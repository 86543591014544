<template>
	<div
		style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 99999"
	>
		<div
			style="position: absolute; width: 100%; height: 100%; background-color: black; opacity: 0.5"
		></div>
		<div
			style="position: relative; height: 100%; background: none; display: flex; flex-direction: column; justify-content: center; z-index: 9999"
		>
			<div class="text-center">
				<div class=""><img :src="require(`@/assets/images${$skin.img}logo_top.png`)" style="width: 240px" /></div>
				<div class="loader ma-auto mt-50 mb-50"></div>
				<div class="mt-10 size-px-24 color-black">Loading...</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Loading'
	,props: ['is_loading']
	,data: function(){
		return {

		}
	}
	,mounted() {

	}
}
</script>

<style>

@keyframes spinner {
	to {transform: rotate(-360deg);}
}

.spin {
	animation: spinner 2s linear infinite;
}

/* HTML: <div class="loader"></div> */
.loader {
	width: 120px;
	height: 48px;
	display: flex;
	justify-content: space-between;
	animation: l3-0 2s infinite alternate;
}
.loader:before,
.loader:after {
	content: "";
	width: 48px;
	background: #3FB8AF;
	animation: l3-1 1s infinite alternate;
}
.loader:after {
	background: #5856D6;
	--s:-1;
}
@keyframes l3-0 {
	0%,40%   {transform: rotate(0)}
	80%,100% {transform: rotate(.5turn)}
}
@keyframes l3-1 {
	80%,100% {transform: translate(calc(var(--s,1)*14px))}
}
</style>